import React, { useState, useEffect } from 'react'
import Seo from '../components/seo'
import Layout from '../components/layout'
import { Container, Row, Col } from 'react-bootstrap'

const Education = (props) => {
  const [treehouseData, setTreehouseData] = useState()
  useEffect(() => {
    fetch(`https://teamtreehouse.com/adamdoe2.json`)
      .then((res) => res.json())
      .then((data) => setTreehouseData(data))
      .catch((e) => console.log('failed to get treehouse data...'))
  }, [])

  return (
    <Layout location={props.location || ''}>
      <Seo
        title="Education | Adam Doe"
        description="nothin"
        //pathname={pageData.slug}
      />
      <Container>
        <Row>
          <Col xs={12}>
            <div className="grid grid--badges">
              {treehouseData
                ? treehouseData.badges.map((badge, index) => {
                    let date = new Date(badge.earned_date)
                    const monthNames = [
                      'Jan',
                      'Feb',
                      'Mar',
                      'Apr',
                      'May',
                      'Jun',
                      'Jul',
                      'Aug',
                      'Sep',
                      'Oct',
                      'Nov',
                      'Dec',
                    ]

                    let shortMonth = monthNames[date.getMonth()]

                    return (
                      <div className="badge--treehouse" key={index}>
                        <div class="w-70 tablet-grid-70 mobile-grid-70 content-meta">
                          <strong>Achievement</strong>
                          <h3>{badge.name}</h3>
                          <div class="content-actions-container">
                            <span class="icon icon-complete"></span>
                            <strong>Achieved</strong>
                            <p></p>
                          </div>
                        </div>
                        <div class="w-30 tablet-grid-30 mobile-grid-30 achievement-hero">
                          <img
                            className="img-fluid"
                            alt="Treehouse Festival (December 2020)"
                            src={`${badge.icon_url}`}
                          />
                        </div>
                      </div>
                    )
                  })
                : 'Loading...'}
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Education
